@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.cdnfonts.com/css/exo-2');
@import url('https://fonts.cdnfonts.com/css/roboto');
@import '@fontsource/bruno-ace/400.css';

// @import "variables";
// @import "mixins";

@font-face {
  font-family: 'Archivo';
  src: url('../font/Archivo_Condensed-ExtraLight.ttf') format('ttf');
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: 'Bruno Ace';
  src: url('../font/Bruno\ Ace\ Regular.ttf') format('ttf');
  font-style: normal;
  line-height: normal;
}

@font-face {
  font-family: 'Bodbug';
  src: url('../font/Bodbug.otf') format('opentype');
  font-style: normal;
  line-height: normal;
}

* {
  margin: 0;
  padding: 0;
}

.detail-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  padding: 'auto';
  padding: 'auto';
}

body {
  font-family: 'Exo 2', sans-serif;
  overflow-x: hidden;
  width: 100vw;
}
body * {
  font-family: 'Exo 2', sans-serif;
  font-family: 'Bruno Ace';
}
.font-exo {
  font-family: 'Exo 2', sans-serif !important;
}
.font-opensans {
  font-family: 'Open Sans', sans-serif !important;
}
.font-manrope {
  font-family: 'Manrope', sans-serif !important;
}
.font-roboto {
  font-family: 'Roboto' !important;
}
.font-archivo {
  font-family: 'Archivo', sans-serif !important;
}

.font-brunoace {
  font-family: 'Bruno Ace' !important;
}

.font-bodbug {
  font-family: 'Bodbug' !important;
}

.object5 {
  animation: rotateScaleReverse 2s linear infinite;
}
.object6 {
  animation: rotateScaleReverse1 2s linear infinite;
}

@keyframes rotateScaleReverse {
  0% {
    transform: rotate(0deg) scale(1);
  }
  5% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(0);
  }
  95% {
    transform: rotate(360deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}
@keyframes rotateScaleReverse1 {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1);
  }
  50% {
    transform: scale(0);
  }
  95% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}
* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    border-radius: 6px;
  }
  .custom-left {
    left: calc(50% - 12px);
  }
  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  }
}

#layout {
  position: relative;
  overflow: hidden;
}
#layout::before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 80%;
  height: 100%;
  transform: translate(-50%, 50%);
  // background-color: #0679ff;
  border-radius: 100%;
  opacity: 0.5;
  filter: blur(400px);
}
.topic-edit {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 6px;
    background-color: #23283d;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
    background-color: #2f80ed;
  }
}
.nav-item {
  transition: 0.4s;
  border-color: transparent;
}
.nav-item:hover {
  color: #1e88e5;
  transition: 0.4s;
  border-color: #1e88e5;
}
.nav-item-active {
  color: #1e88e5;
  transition: 0.4s;
  border-color: #1e88e5;
}
.gradient-border-bg-blue {
  background:
    linear-gradient(#068ac7, #0d1f46) padding-box,
    linear-gradient(180deg, #0bfafd, #18367e) border-box;
  border: 1.5px solid transparent;
  transition: 0.4s;
}

.gradient-border-bg-blue:hover {
  background:
    linear-gradient(#068ac7c4, #0d1f46c7) padding-box,
    linear-gradient(180deg, #0bfafd, #18367e) border-box;
  border: 1.5px solid transparent;
}

.gradient-border-blue {
  background:
    linear-gradient(#1e88e5, #1e88e5) padding-box,
    linear-gradient(180deg, #47a9ff, #1764a8) border-box;
  border-style: solid;
  border-color: transparent;
  border-top-width: 3px;
  border-bottom-width: 3px;
}

.gradient-border-bg-disable {
  background:
    linear-gradient(#595f61, #161616) padding-box,
    linear-gradient(180deg, #595f61, #595f61) border-box;
  border: 1.5px solid transparent;
}
.gradient-border-bg-footer {
  background:
    linear-gradient(#068ac7, #0d1f46) padding-box,
    linear-gradient(180deg, #0bfafd, #18367e) border-box;
  border: 1.5px solid transparent;
}

.gradient-border-bg-purple {
  background:
    linear-gradient(#0f1733, #182963fa) padding-box,
    linear-gradient(180deg, #1b2e72, #223d97) border-box;
  border: 1.5px solid transparent;
  transition: 0.4s all;
}
.gradient-border-bg-purple:hover {
  background:
    linear-gradient(#182963fa, #0f1733) padding-box,
    linear-gradient(180deg, #223d97, #1b2e72) border-box;
  border: 1.5px solid transparent;
  transition: 0.4s all;
}
.gradient-border-bg-void {
  background:
    linear-gradient(to bottom, #0a1128 0%, #0a1128 41%, #0a1128dc 74%, #0a1128a6 95%) padding-box,
    linear-gradient(180deg, #101c41b0, #283b86c4) border-box;
  border: 1.5px solid transparent;
  padding: 1px;
}
.gradient-border-bg-pink {
  background:
    linear-gradient(#1e88e5, #9c27b0) padding-box,
    linear-gradient(180deg, #66aae6, #d166e4) border-box;
  border: 1.5px solid transparent;
  transition: 0.4s all;
}
.gradient-border-bg-panel {
  background:
    linear-gradient(to bottom, #0a1128 0%, #0f1836 41%, #0c192ba6 74%, #142b49a6 95%) padding-box,
    linear-gradient(180deg, #112770, #112770) border-box;
  border: 1.5px solid transparent;
  transition: 0.4s all;
}
.gradient-border-purple {
  background:
    linear-gradient(#0f1733, #0f1733) padding-box,
    linear-gradient(180deg, #1e88e5, #9c27b0) border-box;
  border: 1.5px solid transparent;
  transition: 0.4s all;
}

.gradient-color-title {
  background: linear-gradient(180deg, #1e88e5 10%, #9c27b0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: Rubik; */
}

.gradient-border-bg-mobilemenu {
  background:
    linear-gradient(#0f1733, #182963fa) padding-box,
    linear-gradient(180deg, #1b2e72, #223d97) border-box;
  border: 1.5px solid transparent;
  transition: 0.4s all;
}

.gradient-border-img {
  background:
    linear-gradient(#0b0e1e, #0b0e1e) padding-box,
    linear-gradient(180deg, #17b9ff25, #0b0e1e) border-box;
  border: 1.5px solid transparent;
}

.top-dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  bottom: auto; /* Ensure the dialog doesn't cover the entire screen */
  transform: translateY(0); /* Ensure it's visible at the top */
  max-height: 80%; /* Adjust the max-height as needed */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
}

/* Hide the spinner buttons in Webkit-based browsers (Chrome, Safari, Edge) */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide the spinner buttons in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

button {
  position: relative;
  overflow: hidden;
}
button::before {
  position: absolute;
  top: 0;
  left: -125%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-transform: skewX(135deg);
  transform: skewX(135deg);
}
button.active:before {
  -webkit-animation: shine-hover 0.75s;
  animation: shine-hover 0.75s;
}
@-webkit-keyframes shine-hover {
  0% {
    left: -125%;
  }
  100% {
    left: 125%;
  }
}
@keyframes shine-hover {
  0% {
    left: -125%;
  }
  100% {
    left: 125%;
  }
}

.boaming-image {
  height: auto;
  animation: boom 2s ease-in-out infinite;
}

@keyframes boom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.5);
  }
}

.rotating-svg {
  transition: transform 0.5s ease; /* Smooth rotation transition */
  transform: rotateY(3.142rad); /* Rotate 360 degrees */
}

.dark {
  background-color: black;
}

.rounded-25 {
  border-radius: 25px;
}
.rounded-100 {
  border-radius: 100px;
}
.hero-light {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.9) 0%,
    rgba(255, 255, 255, 0.4) 23.79%,
    rgba(1, 2, 2, 0.4) 47.57%
  );
}
.newest-description {
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 114.286% */
  letter-spacing: -1.12px;
}
.progress-bar {
  width: 386.244px;
  height: 4px;
  flex-shrink: 0;
  background: rgba(217, 217, 217, 0.16);
}
.overlay-card {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.35%, rgba(0, 0, 0, 0.66) 59.37%);
  pointer-events: auto;
  z-index: 1;
}
.view-more {
  border-radius: 1000px;
  background: var(--overlay-white-40, rgba(255, 255, 255, 0.4));
  backdrop-filter: blur(10px);
}
.view-more .title {
  color: var(--Neutral-10, #fff);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
}
.tag {
  border-radius: 100px;
  background: var(--overlay-white-15, rgba(255, 255, 255, 0.15));
}
.skill-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.skill-description {
  color: gray;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.card-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.card-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.4px;
}
.feedback-card {
  border-radius: 32px;
  box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.04);
}
#content[aria-hidden='true'] {
  display: none;
}
#feedback .slick-slide {
  transform: scale(0.8);
  transition: all 0.4s ease-in-out;
  padding: 40px 0;
}

#feedback .slick-slide img {
  max-width: 100%;
  transition: all 0.4s ease-in-out;
}

#feedback .slick-center {
  transform: scale(1.1);
}

#feedback .slider {
  overflow: initial;
}
.blueRadiusButton {
  border-color: #5ac4ff;
  border-radius: 16px;
  background: rgba(90, 196, 255, 0.16);
  color: #5ac4ff;
  font-size: 14px;
  padding: 20px 25px;
}

// ###pagination###
.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    height: 48px;
    width: 48px;
    justify-content: center;
    border: 1px solid rgba(1, 2, 2, 0.16);
    border-radius: 100%;
    border-width: 1px;
    text-align: center;
    margin: auto 4px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
    position: relative;
    transition: 0.4s ease-in-out;
    // color: #010202;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
      transition: 0.4s ease-in-out;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

.team-member-img-on figure {
  position: relative;
  overflow: hidden;
}
.team-member-img-on figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.team-member-img-on figure:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.horizon-container {
  overscroll-behavior: none;
  width: 400%;
  height: calc(100vh - 155px);
  display: flex;
  flex-wrap: nowrap;
}

@media (max-width: 1280px) {
  .horizon-container {
    height: calc(100vh - 125px);
  }
}

@media (max-width: 1024px) {
  .horizon-container {
    height: calc(100vh - 155px);
  }
}

@media (max-width: 768px) {
  .horizon-container {
    height: calc(100vh - 172px);
  }
}
@media (max-width: 640px) {
  .horizon-container {
    height: calc(100vh - 144px);
  }
}

.class_effect {
  animation: classEffect 0.5s forwards;
}

@keyframes classEffect {
  0% {
    background: rgb(255, 255, 255);
    height: 100%;
  }
  100% {
    background: transparent;
    height: 0px;
  }
}

.inline-block {
  display: inline-block;
}

.char-blink {
  width: 3px;
}

.text-50 {
  .char-blink {
    width: 30px;
  }
}

.text-40 {
  .char-blink {
    width: 10px;
  }
}

.text-20 {
  .char-blink {
    width: 5px;
  }
}

.-panel {
  height: 100vh;
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.panel {
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  overflow: hidden;
}

.panel-content {
  overflow-y: scroll;
}

.text-effect {
  text-shadow:
    1px 1px 1px #919191,
    1px 2px 1px #919191,
    1px 3px 1px #919191,
    1px 4px 1px #919191,
    1px 5px 1px #919191,
    1px 6px 1px #919191,
    1px 7px 1px #919191,
    1px 8px 1px #919191,
    1px 9px 1px #919191,
    1px 10px 1px #919191,
    1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2),
    1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}
