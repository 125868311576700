.image-carousel .img-wrapper {
  width: 700px;
  height: auto;
  position: absolute;
  top: 35%;
  transform: perspective(1000px) rotateY(-25deg);
  border-radius: 12px;
}

.image-carousel .img-wrapper img {
  border-radius: 12px;
  width: 100%;
}

#img1 {
  left: 0%;
  z-index: 5;
}
#img2 {
  left: 25%;
  z-index: 4;
}
#img3 {
  left: 50%;
  z-index: 3;
}
