.card {
  width: 50rem;
  height: fit-content;
  border-radius: 10px;
  cursor: pointer;
}

.card img {
  border-radius: 10px;
}

@media (max-width: 1023px) {
  .card {
    width: 35rem;
    height: fit-content;
    border-radius: 10px;
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .card {
    width: 25rem;
    height: fit-content;
    border-radius: 10px;
  }
}
@media (max-width: 535px) {
  .card {
    width: 16rem;
    height: fit-content;
    border-radius: 10px;
  }
}
